body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item-enter {
  opacity: 0;
  transform: translateX(400px)
}

.item-enter-active {
  opacity: 1;
  transition: all 200ms ease-out;
  transform: translateX(0px)
}

.item-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}